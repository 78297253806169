import React from 'react';
import Section from '@latitude/section';
import Slider from 'react-slick';
import Icon from '@latitude/icon';
import { TEXT_TYPE, ICON_VARIANT, BUTTON_THEME } from '@/utils/constants';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import './_hero_banner.scss';
import Button from '@/components/lab-components/Button';

const SLIDER_CONFIG = {
  dots: true,
  infinite: true,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  pauseOnHover: true
};

const ButtonsGroup = ({ buttons, stopWatchCopy = null }) => {
  const getButtonType = index => {
    if (buttons?.length === 2 && index === 1) return BUTTON_THEME.APPLICATION;
    if (buttons?.length === 1) return BUTTON_THEME.APPLICATION;
    return BUTTON_THEME.DEFAULTINVERSE;
  };

  const isHomepageButton = buttons && buttons.length > 2;

  return (
    <div className={`hero-buttons-group hero-buttons-${buttons?.length}`}>
      {buttons?.map(
        (button, index) =>
          button?.url && (
            <Button
              key={button.id}
              theme={isHomepageButton ? undefined : getButtonType(index)}
              rel="noopener noreferrer"
              disableDefaultFunnelTracking={true}
              trackEventData={{
                location: 'hero'
              }}
              href={button.url}
              text={button.text}
              isHomepageButton={isHomepageButton}
            />
          )
      )}
      {buttons?.length === 1 &&
        (stopWatchCopy ? (
          <div className="hero-stop-watch">
            <Icon variant={ICON_VARIANT.STOPWATCH} />
            <span>{stopWatchCopy}</span>
          </div>
        ) : (
          <div className="hero-cta-holder" />
        ))}
    </div>
  );
};

const HeroBannerSlide = ({
  headingSize,
  headingCopy,
  bodyCopy,
  disclaimer,
  image,
  buttons,
  stopWatchCopy
}) => {
  const Typography = TEXT_TYPE[headingSize];
  const OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography>{children}</Typography>
      )
    }
  };
  const OPTIONS_DISCLAIM = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <span className="content disclaimer">{children}</span>
      )
    }
  };
  return (
    <div className="hero-banner-slide typography-common">
      <div className="hero-slide-content">
        {headingCopy && documentToReactComponents(headingCopy, OPTIONS)}
        {bodyCopy && documentToReactComponents(bodyCopy)}
        {buttons?.length > 0 && (
          <ButtonsGroup buttons={buttons} stopWatchCopy={stopWatchCopy} />
        )}
        {disclaimer && documentToReactComponents(disclaimer, OPTIONS_DISCLAIM)}
      </div>
      {image?.file?.url && (
        <div className="hero-slide-img">
          <img src={image.file.url} alt={image.file?.fileName} />
        </div>
      )}
    </div>
  );
};

const HeroBanner = ({ timer, slides, headingSize = 'Heading 1' }) => {
  const settings = {
    ...SLIDER_CONFIG,
    autoplaySpeed: timer ? timer : 5000
  };
  return (
    <Section className="hero-banner">
      <Slider {...settings}>
        {slides?.map(slide => (
          <HeroBannerSlide
            {...slide}
            headingSize={headingSize}
            key={slide.id}
          />
        ))}
      </Slider>
    </Section>
  );
};

export default HeroBanner;
